.nielsen-tables td {
  border-right: 1px solid lightgray;

}

.nielsen-tables td:first-child {
  font-weight: 600 !important;
  border: none;
  border-right: 1px solid lightgray;
}

.nielsen-tables thead tr:first-child th:first-child {
  border-bottom: none;
}

.nielsen-tables tr td:nth-child(2) {
  background-color: white;
}

.nielsen-tables th {
  font-weight: 600 !important;
}
