.createMimioBox {
  width: 100%;
}

@media only screen and (max-width: 840px) {
  .createMimioBox {
    flex-direction: column;
  }
}

@media only screen and (min-width: 1210px) {
  .createMimioBox {
    flex-direction: 'row';
    gap: 1rem;
  }
  .createContainerMob {
    display: none;
  }
  .createContainerDesk {
    display: block;
  }
}

@media only screen and (max-width: 1210px) {
  .createMimioBox {
    flex-direction: column;
    margin: 16px 0;
  }
  .createContainerMob {
    display: block;
  }
  .createContainerDesk {
    display: none;
  }
}
