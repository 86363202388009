/* dashboad.css */

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}

.dashboard-content {
  display: flexbox;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #51619e;
  overflow-y: scroll; /* Enable vertical scrolling when needed */
}

.dashboard-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  color: #fff;
}

/* Input */
.input-container {
  width: 100%;
  margin-bottom: 20px;
}

input[type="text"] {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 5px;
}

/* Chart Section */
.chart-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.linechart-container.center {
  display: flexbox;
  flex-direction: column;
  align-items: center;
}

.chart-row {
  display: flex;
  justify-content: space-between;
  content: normal;
}

.linechart-container .linechart-title {
  text-align: center;
}

.linechart-container,
.piechart-container,
.chart-row {
  box-sizing: border-box;
}

.linechart-container {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
}


.piechart-container {
  display: flex;
  flex-basis: calc(100% - 20px);
  text-align: center;
  font-size: 18px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 20px;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  overflow: calc(100% - 20px);
}

.linechart-title,
.piechart-title,
.messages-title {
  margin-bottom: 10px;
  font-size: 20px;
}

/* Table Section */
.table-section {
  flex: 1;
  overflow: auto; /* Enable vertical scrolling for the table */
  max-height: 500px;
  max-width: 100%;
  border: 1px solid #ccc;
  justify-content: center;
  display: flexbox;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  display: table;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.table-header {
  background-color: #f5f5f5;
  font-weight: bold;
}

.table-row {
  transition: none;
  cursor: pointer;
}

.table-row:nth-of-type(even) {
  background-color: #f9f9f9;
}

.table-row.selected {
  background-color: #b0e0e6;
}

.calendar-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.calendar-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust the opacity as needed */
}

.calendar-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.calendar-popup .calendar-background {
  cursor: pointer;
}

.meetings-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.meeting-date-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.meeting-date-header {
  font-size: 1.2em;
  font-weight: bold;
  padding: 10px;
  background-color: #f5f5f5;
  border-left: 4px solid #4285f4;
  margin-bottom: 10px;
}

.meeting-card {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #C5C5C5;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.meeting-fullscreen-card {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.not-attending {
  opacity: 0.5;
  text-decoration: line-through;
}

.disabled-event {
  border-radius: 8px;
  background-color: #F3F3F3;
}

.date-picker {
  width: 50%;
}

.date-picker input[type="text"] {
  border: none;
}
.date-picker button {
  padding: 0px;
}