html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent !important;
}

.app {
  display: flex;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
}

body.stanford-soopra-ai .app {
  width: 100% !important;
}

.app-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-top: 55px;
  padding: 0;
  box-sizing: border-box;
  border-radius: 6px;
  height: calc(100% - 55px);
}

.app-main > div {
  height: 100%;
}

::-webkit-scrollbar {
  width: 6px;
}
/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 60px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}

.messages-section {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
}

.messages-container {
  overflow: auto;
}

@media (max-width: 600px) {
  .app {
    width: 100% !important;
  }
  .chat-creator-detail {
    display: none;
  }


  /* Nav Bar */
  div.back-button-box {
    min-width: 40px;
  }

  div.logo-box {
    position: relative;
  }

  div.credits-box {
    margin-right: 1rem;
  }


  /* Course Details */
  div.course-details-header-box {
    flex-direction: column;
  }

  div.course-details-buttons-box {
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 1rem;
  }

  div.course-details-buttons-box button {
    margin: 0px;
  }

  /* div.course-details-buttons-box button.course-details-temp-take-course {
    display: none;
  } */

  div.course-details-creator-box {
    width: 100%;
  }

  div.course-details-creator-box div {
    margin-left: 16px;
  }

  div.course-details-description-box {
    margin-top: 1rem;
  }

  div.course-details-overview-box {
    margin-top: 1rem;
    margin-bottom: 0rem;
  }


  /* Subscriptions page */
  div.my-subscriptions-box {
    border: none;
    padding: 15px;
  }

  div.my-subscriptions-box div.mobile-column {
    flex-direction: column;
  }

  div.my-subscriptions-box div.mobile-margin-top {
    margin-top: 20px;
  }

  div.mobile-fullwidth {
    width: 100%;
  }

  div.mobile-nopaddingtop {
    padding-top: 0px;
  }

  button.mobile-fullwidth {
    width: 100%;
  }

  button.mobile-nomargin {
    margin: 0;
  }

  button.mobile-margintop {
    margin: 0;
    margin-top: 1rem;
  }

  table.payments-table {
    th {
      padding-left: 0;
      padding-right: 0;
    }

    td {
      padding-left: 0;
      padding-right: 0;
    }

    th:nth-child(1) { padding-right: 1rem; width: 10%;}
    td:nth-child(3) { width: 20%; }
    td:nth-child(4) { width: 10%; }
  }

  .mobile-hidden {
    display: none !important;
  }

  .mobile-visible {
    display: flex !important;
  }

  .mobile-padding0 {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 480px) {
  /*   Grid layout for dektop signin.
  .app-main {
    box-shadow: 0 6px 18px #ccc;
    margin: 0.6rem;
    margin: 0;
  }


  .app {
    display: grid;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgb(223, 223, 239);
  }
 */

  /* .messages-container {
    height: calc(100vh - 136px);
  } */

  .logo-img {
    padding: 1rem;
    max-width: 75%;
  }
}

a {
  text-decoration-line: none;
  -webkit-text-decoration-line: none;
  color: inherit;
}

.logo-img {
  padding: 1rem;
}

.maintenance-img {
  padding: 0.4rem;
  border-radius: 12px;
}

.user-streaks-profile {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.user-streaks-list {
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  @media (max-width: 480px) {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
  }
}

.user-streaks-single {
  width: 180px;
  min-width: 180px;
  max-width: 180px;
}

.custom-bottom{
  height: 5px;
  width: 100%;
  position: relative;
}

ol.terms-list > li > ol{
  list-style-type: lower-alpha;
}

ol.terms-list > li > ol > li > ol{
  list-style-type: lower-roman;
}

ol.terms-list > li > ol > li > ol > li > ol{
  list-style-type: decimal;
}

ol.terms-list > li > ol > li > ol > li > ol > li > ol{
  list-style-type: lower-alpha;
}

ol.terms-list a {
  text-decoration-line: underline;
  color: blue;
  font-weight: 400;
}

.contact-info a {
  text-decoration-line: underline;
  color: blue;
  font-weight: 400;
}
