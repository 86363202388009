/* dashboad.css */

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
}

.dashboard-content {
  display: flexbox;
  flex-direction: column;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 8px;
  background-color: #51619e;
}

.dashboard-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 25px;
  color: #fff;
}

/* Input */
.input-container {
  width: 100%;
  margin-bottom: 20px;
}

.dashboard-section input[type='text'] {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin: 5px;
}

/* Chart Section */
.chart-section {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  contain: content;
  max-width: 100%;
}

.linechart-container.center {
  align-items: center;
}

.chart-row {
  display: flex;
  flex: 1;
  justify-content: space-between;
  content: normal;
  max-width: 100%;
  contain: content;
}

.linechart-container .linechart-title {
  text-align: center;
}

.linechart-container {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flexbox;
  margin-bottom: 40px;
  overflow: calc(100% - 20px);
  flex-direction: column;
}

.piechart-container {
  width: 100%;
  text-align: center;
  font-size: 18px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: flexbox;
  margin-bottom: 40px;
  margin-right: 20px;
  overflow: calc(100% - 20px);
  flex-direction: column;
}

.linechart-title,
.piechart-title,
.messages-title {
  margin-bottom: 10px;
  font-size: 20px;
}

/* Table Section */
.table-section {
  flex: 1;
  overflow: auto; /* Enable vertical scrolling for the table */
  max-height: 500px;
  max-width: 100%;
  border: 1px solid #ccc;
  justify-content: center;
  display: flexbox;
}

.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ccc;
  display: table;
}

.table th,
.table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}

.table-header {
  background-color: #f5f5f5;
  font-weight: bold;
}

.table-row {
  transition: none;
  cursor: pointer;
}

.table-row:nth-of-type(even) {
  background-color: #f9f9f9;
}

.table-row.selected {
  background-color: #b0e0e6;
}

.calendar-popup {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  position: fixed; /* Fixed positioning to cover the whole screen */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* High z-index to ensure it's on top of other elements */
}

.calendar-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5); /* Adjust the opacity as needed */
}

.calendar-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  /* Add max-width and max-height if needed to control the size of the calendar */
}
.calendar-popup .calendar-background {
  cursor: pointer;
}

.button-cancel-edit:hover{
  background-color: #8c8c8c !important;
  box-shadow: 1px 1px 7px rgb(134, 134, 134) !important;
}

@media screen and (max-width: 1000px) {
  .dashboard-title {
    font-size: 15px;
  }

  .chart-row {
    display: block;
    justify-content: space-between;
    content: normal;
    max-width: 100%;
    contain: content;
  }

  .chart-section {
    /* Adjust styles to properly space between columns */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    /* Remove display: contents; and contain: content; */
  }
}
