.slick-slide {
    display: flex !important;
    align-items: center;
    justify-content: center;
    height: 150px; 
    max-height: 200px; 
  }

  .slick-list {
    height: 180px; 
  }

  .custom-dots .slick-dots li button:before {
    color: #00A3FF; 
  }
  
  .custom-dots .slick-dots li.slick-active button:before {
    color: #00A3FF; 
  }



  /* Add this to your courseItem.css */
.responsive-grid-item {
    padding-top: 20px;
  }
  
  @media only screen and (max-width: 600px) {
    .responsive-grid-item img {
      padding-left: 0 !important;
      width: 100%;
      height: auto;
    }
  }

  .hide-on-mobile {
    display: none;
  }
  
  @media only screen and (min-width: 600px) {
    .hide-on-mobile {
      display: block;
    }
  }

  .banner-image {
    width: 100%; 
    max-width: 100%; 
    max-height: 190px; 
    height: auto; 
    object-fit: contain; 
    padding-top: 20px; 
    display: block; 
    margin: 0 auto; 
  }
  