.level {
  justify-content: center;
  width: 100%;
  max-width: 400px;
  flex-wrap: wrap;
  /* padding: 1.4rem; */
  border: 1px solid #808080;
  border-radius: 10px;
}
.currentLevel {
  border-color: #029ad3;
}

.inactiveLevel {
  border-color: #808080;
}

.completedLevel {
  border-color: #c55996;
}

.trainInputs input[type='text'] {
  border: 0;
}

.levelQuestionItem:not(:last-child) {
  border-bottom: 1px solid rgba(8, 39, 48, 0.8);
}
