.markdown.incoming * {
  color: #000;
  font-size: 0.91rem;
}

.markdown.outgoing * {
  color: #fff;
  font-size: 0.91rem;
}

.markdown a {
  color: #4177ed !important;
  text-decoration: underline;
}

.markdown p {
  margin-bottom: 0;
}

.markdown a:visited {
  color: #ED7841 !important;
  text-decoration: underline;
}

.markdown ol {
  margin-left: 16px;
  white-space: normal;
}

.markdown h1, .markdown h2, .markdown h3, .markdown h4 {
  letter-spacing: normal;
  text-transform: none;
  margin-bottom: 0;
}
