
.search-query-box {
    display: flex;
    flex-direction: row;
    background: #e4f3ff;
    border-radius: 15px 15px 15px 15px;
  } 
  
  .search-query-input {
    width: 100%;
    padding: 10px;
    /* border: 1px solid #34a3ff; */
    border: none;
    border-radius: 15px 0px 0px 15px;
    transition: all 50ms ease;
    border-right: none;
    background: none;
    }
  
   .search-query-btn {
    height: 100%;
    padding: 5px 5px;
    border-radius: 0px 15px 15px 0px;
    background: none;
    /* border: 1px solid #34a3ff; */
    border: none;
    border-left: none;
    cursor: pointer;
    transition: all 50ms ease;
    display: flex;
    align-items: center;
    justify-content: center;
   }
  
   
   .search-query-input:focus {
    border: 2px solid #34a4ff;
    border-right: none;
    outline: none;
   }
  
   .search-query-box > .search-query-input:focus + .search-query-btn{
    border: 2px solid #34a4ff !important;
    border-left: none !important;
    outline: none;
   }
  
   .search-query-btn:hover{
    background: #34a4ff34;
   }

   .search-query-header-container {
    z-index: 999;
   }
  
   .course-subsection::-webkit-scrollbar {
    height: 5px;
   }

   .query-response-item {
    
    transition: all 150ms ease;
   }
   
   .query-response-item:hover {
    box-shadow: 0px 5px 5px #d2d2d2;
    transform: translateY(-2px);
    transition: all 150ms ease;
   }

   .sign-up-sign-in-button {
    width: 100%;
    z-index: 50;
    justify-content: right;
    padding: 50px;
    right: 0px;
   }

   .sign-in-mobile-component {
    display: none;
   }

   .query-markdown-response-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
   }

   .query-markdown-response-container p {
    color: black;
   }

   .query-markdown-response-container p, 
   .query-markdown-response-container h1, 
   .query-markdown-response-container h2, 
   .query-markdown-response-container h3,
   .query-markdown-response-container ol,
   .query-markdown-response-container li {
    font-size: 15px;
   }

   .card-loading {
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
   }

   @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
   @media screen and (max-width: 768px) {
    .search-result-container {
        display: flex !important;
        flex-direction: column-reverse;
    }

    .search-response-card-container {
        height: max-content !important;
        overflow: auto !important;
    }
    
    .search-summary-card-container {
        border-left: none;
        padding: 10px !important;
    }

    .search-query-header-container {
        flex-direction: column !important;
        margin-bottom: 0px !important;
    }

    .search-query-box-container {
        padding: 0px 10px!important;
    }

    .sign-up-sign-in-button {
        padding: 20px;
    }

    .search-query-header-image-container {
        width: 90%;
    }

    .search-query-header-image-wrapper {
        width: 50%;
    }
    
    .query-response-item-modal {
        width: 90vw !important;
    }

    .query-response-item-modal::-webkit-scrollbar {
        display: none;
    }

    .course-subsection-modal {
        font-size: 11px !important;
    }

    .course-subsection-modal-item {
        min-width: fit-content !important;
    }

    .search-home-btn-grp {
        width: 90%;
        justify-content: center;
    }

    .sign-up-sign-in-component {
        display: none;
    }

    .sign-in-mobile-component {
        display: block;
    }
   }